import React from "react";
import { FaReact } from "react-icons/fa";
import { RiJavascriptFill } from "react-icons/ri";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { SiAdobephotoshop } from "react-icons/si";
import { SiAdobeillustrator } from "react-icons/si";
import { SiCanva } from "react-icons/si";
import { FaWordpress } from "react-icons/fa";
import { FaFigma } from "react-icons/fa";

import "./Skills.css";

const SkillsCard = () => {
  return (
    <>
      <h4>Design Tools</h4>
      <div className="skills-group">
        <div className="skills-card">
          <SiAdobephotoshop style={{ color: "hotpink", fontSize: "3.5rem" }} />
          <h5>Photoshop</h5>
        </div>
        <div className="skills-card">
          <SiAdobeillustrator
            style={{ color: "hotpink", fontSize: "3.5rem" }}
          />
          <h5>Illustrator</h5>
        </div>

        <div className="skills-card">
          <FaFigma style={{ color: "hotpink", fontSize: "3.5rem" }} />
          <h5>Figma</h5>
        </div>
        <div className="skills-card">
          <SiCanva style={{ color: "hotpink", fontSize: "3.5rem" }} />
          <h5>Canva</h5>
        </div>
      </div>
      <br></br>
      <h4>Coding Tools</h4>
      <div className="skills-group">
        <div className="skills-card">
          <FaHtml5 style={{ color: "hotpink", fontSize: "3.5rem" }} />
          <h5>HTML</h5>
        </div>
        <div className="skills-card">
          <FaCss3Alt style={{ color: "hotpink", fontSize: "3.5rem" }} />
          <h5>CSS</h5>
        </div>
        <div className="skills-card">
          <RiJavascriptFill style={{ color: "hotpink", fontSize: "3.5rem" }} />
          <h5>JavaScript</h5>
        </div>
        <div className="skills-card">
          <FaReact style={{ color: "hotpink", fontSize: "3.5rem" }} />
          <h5>React</h5>
        </div>
        <div className="skills-card">
          <FaWordpress style={{ color: "hotpink", fontSize: "3.5rem" }} />
          <h5>WordPress</h5>
        </div>
      </div>
    </>
  );
};

export default SkillsCard;
