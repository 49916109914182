import React from "react";
import "./Skills.css";
import SkillsCard from "./SkillsCard";

const Skills = () => {
  return (
    <>
      <div className="skills" id="skills">
        <h2>Skills</h2>
        <SkillsCard />
      </div>
    </>
  );
};

export default Skills;
