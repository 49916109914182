import React from "react";
import "./Projects.css";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardLink,
  Container,
} from "reactstrap";

const Projects = () => {
  return (
    <>
      <Container id="projects">
        {/* <div className="projects-section" > */}
        <h2>Website Projects</h2>
        <div className="card-container">
          <Card // The Bindery Denver
            className="card"
            style={{
              width: "18rem",
              marginBottom: "25px",
            }}
          >
            <CardBody>
              <CardTitle
                className="card-title"
                tag="h5"
                style={{ color: "var(--primary-color)" }}
              >
                The Bindery Denver
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                WordPress, HTML & CSS
              </CardSubtitle>
            </CardBody>
            <img
              alt="The Bindery Denver"
              src="https://res.cloudinary.com/dl11m63ow/image/upload/v1723754228/Patsy%20Saurer%20Portfolio/The_Bindery_Website_Mockup_reoykl.png"
              width="100%"
            />
            <CardBody>
              <CardText>
                A New American fine dining restaurant in Denver with a beautiful
                gallery and menu.
              </CardText>
              {/* <CardLink href=""><Button color="warning" style={{border:"none"}}>View App</Button></CardLink> */}
              <CardLink href="https://thebinderydenver.com/" target="blank">
                <Button color="warning" style={{ border: "none" }}>
                  View Website
                </Button>
              </CardLink>
            </CardBody>
          </Card>

          <Card // cake recipe app
            className="card"
            style={{
              width: "18rem",
              marginBottom: "25px",
            }}
          >
            <CardBody>
              <CardTitle
                className="card-title"
                tag="h5"
                style={{ color: "var(--primary-color)" }}
              >
                Cakes by Patsy
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                WordPress, HTML & CSS
              </CardSubtitle>
            </CardBody>
            <img
              alt="Cakes by Patsy"
              src="https://res.cloudinary.com/dl11m63ow/image/upload/v1723754594/Patsy%20Saurer%20Portfolio/Cakes_by_Patsy_Website_Mockup_sexoh0.png"
              width="100%"
            />
            <CardBody>
              <CardText>
                Full-Stack App deployed with full CRUD functionality and my
                personal cakes.
              </CardText>
              <CardLink href="https://cakesbypatsy.com" target="blank">
                <Button color="warning" style={{ border: "none" }}>
                  View Website
                </Button>
              </CardLink>
            </CardBody>
          </Card>

          <Card // cake memory game
            style={{
              width: "18rem",
              marginBottom: "25px",
            }}
          >
            <CardBody>
              <CardTitle tag="h5" style={{ color: "var(--primary-color)" }}>
                Cake Memory
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h7">
                React
              </CardSubtitle>
            </CardBody>
            <img
              alt="Cake Memory Game"
              src="https://res.cloudinary.com/dl11m63ow/image/upload/v1723753951/Patsy%20Saurer%20Portfolio/Cake_Memory_Game_w1uk71.png"
              width="100%"
            />
            <CardBody>
              <CardText>
                My love for cakes turned into a cute React game adding a cool
                confetti feature.
              </CardText>
              <CardLink href="https://cake-memory.onrender.com/" target="blank">
                <Button color="warning" style={{ border: "none" }}>
                  Play Me
                </Button>
              </CardLink>
              <CardLink
                href="https://github.com/patsysaurer/cake-memory"
                target="blank"
              >
                <Button
                  style={{
                    backgroundColor: "var(--secondary-color)",
                    border: "var(--secondary-color)",
                    color: "var(--primary-dark)",
                  }}
                >
                  View Code
                </Button>
              </CardLink>
            </CardBody>
          </Card>

          <Card // tic-tac-toe game
            style={{
              width: "18rem",
              marginBottom: "25px",
            }}
          >
            <CardBody>
              <CardTitle tag="h5" style={{ color: "var(--primary-color)" }}>
                Tic Tac Toe
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                React
              </CardSubtitle>
            </CardBody>
            <img
              alt="Tic Tac Toe"
              src="https://res.cloudinary.com/dl11m63ow/image/upload/v1723752663/Patsy%20Saurer%20Portfolio/Tic-TacToe_Game_mslmpb.png"
              width="100%"
            />
            <CardBody>
              <CardText>
                Collaborative project I contributed to creating a classic game
                in React.
              </CardText>
              <CardLink
                href="https://tic-tac-toe-9qfh.onrender.com/"
                target="blank"
              >
                <Button color="warning" style={{ border: "none" }}>
                  Play Me
                </Button>
              </CardLink>
              <CardLink
                href="https://github.com/patsysaurer/tic-tac-toe-alex-patsy-derek"
                target="blank"
              >
                <Button
                  style={{
                    backgroundColor: "var(--secondary-color)",
                    border: "var(--secondary-color)",
                    color: "var(--primary-dark)",
                  }}
                >
                  View Code
                </Button>
              </CardLink>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default Projects;
