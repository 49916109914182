import React from "react";
import "./Hero.css";

const Intro = () => {
  return (
    <div id="intro-section">
      <div className="text-content">
        <h3 className="hello">Hello! I'm</h3>
        <h1>Patsy Saurer</h1>
        <h3>A web developer</h3>
        <br />
      </div>
    </div>
  );
};

export default Intro;
